// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knihy-js": () => import("./../src/pages/knihy.js" /* webpackChunkName: "component---src-pages-knihy-js" */),
  "component---src-pages-o-nas-js": () => import("./../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-books-detail-js": () => import("./../src/templates/books-detail.js" /* webpackChunkName: "component---src-templates-books-detail-js" */),
  "component---src-templates-postcards-detail-js": () => import("./../src/templates/postcards-detail.js" /* webpackChunkName: "component---src-templates-postcards-detail-js" */),
  "component---src-templates-postcards-js": () => import("./../src/templates/postcards.js" /* webpackChunkName: "component---src-templates-postcards-js" */),
  "component---src-templates-posters-detail-js": () => import("./../src/templates/posters-detail.js" /* webpackChunkName: "component---src-templates-posters-detail-js" */),
  "component---src-templates-posters-js": () => import("./../src/templates/posters.js" /* webpackChunkName: "component---src-templates-posters-js" */)
}

